import { Link, navigate } from "gatsby";
import React, { useEffect } from "react";

const Buttonevents = ({ navigatetopage }) => {
  const scrollToSection = () => {
    document.querySelectorAll("a.scroller").forEach((a) => {
      a.addEventListener("click", (event) => {
        if (event.target.getAttribute("href")) {
          event.preventDefault();
          const gotoSection = () => {
            const hash = event.target.getAttribute("href");
            const hashSection = hash.split("/#").pop();
            const headerHeight = 60;
            const scrollTarget = document.querySelector(`#${hashSection}`);
            if (scrollTarget) {
              window.scrollTo({
                top: scrollTarget.offsetTop - headerHeight,
                behavior: "smooth",
              });
            }
          };
          const scrollRootTarget = event.target.getAttribute("data-rootlink");
          if (scrollRootTarget) {
            navigate(scrollRootTarget);
            gotoSection();
          } else {
            gotoSection();
          }
        }
      });
    });
  };

  useEffect(() => {
    scrollToSection();
  }, []);

  return (
    <div className="buttonevents-wrapper">
      <div className="row">
        <div className="col-md-12">
          <div className="btn-events">
            <a href="#eventstory"
              className="btn btn-blue scroller"
              type="button"
              rel="noreferrer"
              as={Link}
              to="/eventstory"
            >
              <span
                data-rootlink="/eventstory"
                id="horticultre"
                className="header-link"
              >
                {" "}
                Event Story
              </span>
            </a>

            <a
              className="btn btn-grey"
              type="button"
              href="#impact"
              rel="noreferrer"
            >
              Impact
            </a>

            <a
              className="btn btn-grey"
              type="button"
              href="#numberofrunner"
              rel="noreferrer"
            >
              Number of Runners
            </a>

            <a
              className="btn btn-grey"
              type="button"
              href="#winners"
              target="_parent"
              rel="noreferrer"
            >
              Winners
            </a>

            <a
              className="btn btn-grey"
              type="button"
              href="#picture"
              target="_parent"
              rel="noreferrer"
            >
              Pictures
            </a>
            <a
              className="btn btn-grey"
              type="button"
              href="#videopost"
              rel="noreferrer"
            >
              Video Post Run
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buttonevents;
