import React from "react";

import Layout from "../layout/index";

import Seo from "../components/seo/index";

import Gkrimpact from "../components/feb2023/gkrimpact";

import Febbreadcrumbpages from "../components/feb2023/febbreadcrumb";

import Buttonevents from "../components/feb2023/buttoneventsfeb";

import Videopostruncontent from "../components/feb2023/videopost";

import Eventstorypages from "../components/feb2023/eventstory";

import Numberofrunner from "../components/feb2023/numberofrunner";

import Winnerscontent from "../components/feb2023/winners";

// import Picturesec from "../components/feb2023/picture";

import RecentPlantations  from "../components/feb2023/recentplantation";

import SimpleReactLightbox from "simple-react-lightbox";

import Socialiconright from "../components/home/social-icon-right";

const Feb2023Page = () => {
  const navigatetopage = (data) => {
    console.log(data);
  };
  return (
    <div>
      <Seo title="Green Heartfulness Run - Feb 2023" />
      <Layout isSticky>
        <Buttonevents navigatetopage={navigatetopage} />
        <section>
          <Febbreadcrumbpages />
        </section>
        <section>
        <Socialiconright />
        </section>
        <section id="eventstory">
          <Eventstorypages />
        </section>
        <section id="impact">
          <Gkrimpact />
        </section>
        <section id="numberofrunner">
          <Numberofrunner />
        </section>
        <section id="winners">
          <Winnerscontent />
        </section>
        <section id="picture">
          {/* <Picturesec /> */}
          {
          <SimpleReactLightbox>
            <section id="recentplantation">
              <RecentPlantations />
            </section>
          </SimpleReactLightbox>
        }
        </section>
        <section id="videopost">
          <Videopostruncontent />
        </section>
      </Layout>
    </div>
  );
};

export default Feb2023Page;
