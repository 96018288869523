import React from "react";
import gkrpic from "../../assets/images/home/gkr/gkr-impact.png";
import gkrground from "../../assets/images/home/gkr/before.png";
import gkrafter from "../../assets/images/home/gkr/after.png";
import treesplanned from "../../assets/images/home/gkr/trees-planned-pic.png";

export default function Gkrimpact() {
  return (
    <div className="gkrimpact-wrpper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <img src={gkrpic} className="img-fluid" alt="GKR" />
          </div>

          <div className="col-md-7">
            <h3 className="title-heading"> GGHR IMPACT TOWARDS PLANTATION </h3>
            <p>
              {" "}
              In an age where we are talking about climate change and carbon
              footprints, Green Heartfulness Run would be the most thoughtful climate
              conscious run possible and all its proceeds will go to Forests by
              Heartfulness, an initiative of Heartfulness institution, to
              nurture, protect and conserve nature, endemic and endangered trees
              species of India.
            </p>

            <div className="impact-wrapper-box">
              <div className="green-wrpper">
                <p>
                  {" "}
                  To honor the runners of Green Heartfulness Run, <b> 4000 </b> trees
                  of <b> 70 </b> varieties were planted during{" "}
                  <b> Feb-May 2023 </b>{" "}
                </p>
              </div>

              <div className="pic-wrapper" style={{display:"flex", justifyContent:"center"}}>
                <img src={gkrground} className="img-fluid" alt="GKR" />
              </div>

              <div className="pic-wrapper" style={{display:"flex", justifyContent:"center"}}>
                <img src={gkrafter} className="img-fluid" alt="GKR" />
              </div>
            </div>

            {/* <div className="Categories-wrapper">
              <div className="box-wrapper lightblue">
                <p> <b> Mahogany </b> <span className="small-text">  (Switenia Mahogany) </span> </p>
              </div> 
              <div className="box-wrapper dull-blue">
                <p> <b> Champak </b> <span className="small-text"> (Magnolia Champaca) </span>  </p>
              </div>
              <div className="box-wrapper sky-blue">
                <p> <b> Golden Shower Tree </b> <span className="small-text"> (Cassia Fistula) </span>   </p>
              </div>
              <div className="box-wrapper high-blue">
                <p> <b> Pride of India </b>  <span className="small-text"> (Lagerstroemia Speciosa) </span>  </p>
              </div>
            </div> */}

            {/* <div className="Categories-wrapper">
              <div className="box-wrapper penblue">
                <p> <b> Agathi  </b>  <span className="small-text">  (Sesbania Grandiflora) </span> </p>
              </div> 
              <div className="box-wrapper koro-blue">
                <p> <b>Malabar Nut  </b> <span className="small-text"> (Justicia Adathoda) </span>  </p>
              </div>
              <div className="box-wrapper dark-green">
                <p> <b> Sandalwood  </b> <span className="small-text"> (Santalum Album) </span>   </p>
              </div>
             </div> */}

             <h4 className="mb-4 mt-2"> <b> TREEES PLANTED </b>  </h4>

             <img src={treesplanned} className="img-fluid" alt="Trees planned" />

             <div className="know_more_btn_container">
             <a href="https://heartfulness.org/forests/" className="know_more_btn btn-white" target="_blank"  rel="noreferrer"> Know more about Forests
by Heartfulness </a>

             </div>
          

          </div>
        </div>
      </div>
    </div>
  );
}
