import React from "react";

import Form from "react-bootstrap/Form";

import { Tabs, Tab, TabContent } from "react-bootstrap";

import one from "../../assets/images/feb2023/winners/one.png";

import dreessicon from "../../assets/images/feb2023/winners/dress-icon.png";

import peaceicon from "../../assets/images/feb2023/winners/peace-icon.png";

import gunicon from "../../assets/images/feb2023/winners/gun-time.png";

import chipicon from "../../assets/images/feb2023/winners/chip-time.png";

import two from "../../assets/images/feb2023/winners/two.png";

import three from "../../assets/images/feb2023/winners/three.png";

export default function Winnerscontent() {
  return (
    <div id="winners" className="winners-wraper mt-5 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="title-heading"> WINNERS </h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Tabs
              defaultActiveKey="21km"
              transition={false}
              id="noanim-tab-example"
              className="mb-3 winners-tabs"
            >
              <Tab eventKey="21km" title="21 Km">
                <TabContent tabTitle="21 Km">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="title-heading">
                        {" "}
                        21 Km (female)  30 & Above
                      </h3>
                    </div>
                    <div className="col-md-4">
                      <div className="box-wrapper">
                        <div className="head-item">
                          <span className="number-span-item">
                            <img
                              className="img-fluid number"
                              src={one}
                              width="100%"
                              alt="one"
                            />
                          </span>
                          <span className="name-span-item">
                            {" "}
                            <p>
                              {" "}
                              <b> Kitu Sheoran </b>{" "}
                            </p>
                          </span>
                          <span className="age-span-item">
                            {" "}
                            <p> Age </p>{" "}
                            <p>
                              {" "}
                              <b> 35 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                        <hr className="line-wrapper"></hr>
                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={dreessicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Bib{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 21127 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name no-padding">
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={peaceicon}
                                  width="100%"
                                  alt="one"
                                />{" "}
                              </span>{" "}
                              Pace
                            </p>
                            <p>
                              {" "}
                              <b> 06:04 </b>{" "}
                            </p>
                          </span>
                        </div>

                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={gunicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Gun Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 02:08:31 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={chipicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Chip Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 02:08:09 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>

                        <div className="last-item">
                          <span className="km-name">
                            {" "}
                            <p> 3.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 0:22:24 </b>{" "}
                            </p>{" "}
                          </span>
                          <span className="km-name">
                            {" "}
                            <p> 4.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 0:30:41 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="km-name">
                            {" "}
                            <p> 17.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 1:48:19 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="box-wrapper">
                        <div className="head-item">
                          <span className="number-span-item">
                            <img
                              className="img-fluid number"
                              src={two}
                              width="100%"
                              alt="one"
                            />
                          </span>
                          <span className="name-span-item">
                            {" "}
                            <p>
                              {" "}
                              <b> Aditya Devi M </b>{" "}
                            </p>
                            <p> </p>
                          </span>
                          <span className="age-span-item">
                            {" "}
                            <p> Age </p>{" "}
                            <p>
                              {" "}
                              <b> 44 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                        <hr className="line-wrapper"></hr>
                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={dreessicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Bib{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 21068 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name no-padding">
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={peaceicon}
                                  width="100%"
                                  alt="one"
                                />{" "}
                              </span>{" "}
                              Pace
                            </p>
                            <p>
                              {" "}
                              <b> 06:05 </b>{" "}
                            </p>
                          </span>
                        </div>

                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={gunicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Gun Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 02:08:44 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={chipicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Chip Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 02:08:39 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>

                        <div className="last-item">
                          <span className="km-name">
                            {" "}
                            <p> 3.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:19:42 </b>{" "}
                            </p>{" "}
                          </span>
                          <span className="km-name">
                            {" "}
                            <p> 4.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:27:54 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="km-name">
                            {" "}
                            <p> 17.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 01:46:42</b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="box-wrapper">
                        <div className="head-item">
                          <span className="number-span-item">
                            <img
                              className="img-fluid number"
                              src={three}
                              width="100%"
                              alt="one"
                            />
                          </span>
                          <span className="name-span-item">
                            {" "}
                            <p>
                              {" "}
                              <b> Syamala Devi Peri </b>{" "}
                            </p>
                            <p> </p>
                          </span>
                          <span className="age-span-item">
                            {" "}
                            <p> Age </p>{" "}
                            <p>
                              {" "}
                              <b> 49 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                        <hr className="line-wrapper"></hr>
                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={dreessicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Bib{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 21051</b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name no-padding">
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={peaceicon}
                                  width="100%"
                                  alt="one"
                                />{" "}
                              </span>{" "}
                              Pace
                            </p>
                            <p>
                              {" "}
                              <b> 06:08 </b>{" "}
                            </p>
                          </span>
                        </div>

                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={gunicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Gun Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 02:13:00 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={chipicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Chip Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b>02:09:38 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>

                        <div className="last-item">
                          <span className="km-name">
                            {" "}
                            <p> 3.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:22:57 </b>{" "}
                            </p>{" "}
                          </span>
                          <span className="km-name">
                            {" "}
                            <p> 4.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:31:01 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="km-name">
                            {" "}
                            <p> 17.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 01:58:35 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-4 mb-4">
                      <h3 className="title-heading"> 21 Km (female) Under 30</h3>
                    </div>

                    <div className="col-md-4">
                      <div className="box-wrapper">
                        <div className="head-item">
                          <span className="number-span-item">
                            <img
                              className="img-fluid number"
                              src={one}
                              width="100%"
                              alt="one"
                            />
                          </span>
                          <span className="name-span-item">
                            {" "}
                            <p>
                              {" "}
                              <b> Keesari. Keerthana </b>{" "}
                            </p>
                            <p> </p>
                          </span>
                          <span className="age-span-item">
                            {" "}
                            <p> Age </p>{" "}
                            <p>
                              {" "}
                              <b> 20 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                        <hr className="line-wrapper"></hr>
                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={dreessicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Bib{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 21303 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name no-padding">
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={peaceicon}
                                  width="100%"
                                  alt="one"
                                />{" "}
                              </span>{" "}
                              Pace
                            </p>
                            <p>
                              {" "}
                              <b> 06:47 </b>{" "}
                            </p>
                          </span>
                        </div>

                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={gunicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Gun Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 02:23:40 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={chipicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Chip Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 02:23:16 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>

                        <div className="last-item">
                          <span className="km-name">
                            {" "}
                            <p> 3.5Km </p>{" "}
                            <p>
                              {" "}
                              <b>00:22:52 </b>{" "}
                            </p>{" "}
                          </span>
                          <span className="km-name">
                            {" "}
                            <p> 4.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:31:31 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="km-name">
                            {" "}
                            <p> 17.5Km </p>{" "}
                            <p>
                              {" "}
                              <b>01:56:57 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="box-wrapper">
                        <div className="head-item">
                          <span className="number-span-item">
                            <img
                              className="img-fluid number"
                              src={two}
                              width="100%"
                              alt="one"
                            />
                          </span>
                          <span className="name-span-item">
                            {" "}
                            <p>
                              {" "}
                              <b>Kothakapeta Poojitha </b>{" "}
                            </p>
                          </span>
                          <span className="age-span-item">
                            {" "}
                            <p> Age </p>{" "}
                            <p>
                              {" "}
                              <b> 20 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                        <hr className="line-wrapper"></hr>
                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={dreessicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Bib{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 21305 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name no-padding">
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={peaceicon}
                                  width="100%"
                                  alt="one"
                                />{" "}
                              </span>{" "}
                              Pace
                            </p>
                            <p>
                              {" "}
                              <b> 06:48 </b>{" "}
                            </p>
                          </span>
                        </div>

                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={gunicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Gun Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 02:24:10 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={chipicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Chip Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 02:23:44 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>

                        <div className="last-item">
                          <span className="km-name">
                            {" "}
                            <p> 3.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:22:05 </b>{" "}
                            </p>{" "}
                          </span>
                          <span className="km-name">
                            {" "}
                            <p> 4.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:30:19 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="km-name">
                            {" "}
                            <p> 17.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 01:56:59 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="box-wrapper">
                        <div className="head-item">
                          <span className="number-span-item">
                            <img
                              className="img-fluid number"
                              src={three}
                              width="100%"
                              alt="one"
                            />
                          </span>
                          <span className="name-span-item">
                            {" "}
                            <p>
                              {" "}
                              <b> Banala. Ganga </b>{" "}
                            </p>
                          </span>
                          <span className="age-span-item">
                            {" "}
                            <p> Age </p>{" "}
                            <p>
                              {" "}
                              <b> 19 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                        <hr className="line-wrapper"></hr>
                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={dreessicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Bib{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 21304 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name no-padding">
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={peaceicon}
                                  width="100%"
                                  alt="one"
                                />{" "}
                              </span>{" "}
                              Pace
                            </p>
                            <p>
                              {" "}
                              <b> 06:57 </b>{" "}
                            </p>
                          </span>
                        </div>

                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={gunicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Gun Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 02:27:10 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={chipicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Chip Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 02:26:46</b>{" "}
                            </p>{" "}
                          </span>
                        </div>

                        <div className="last-item">
                          <span className="km-name">
                            {" "}
                            <p> 3.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:22:57</b>{" "}
                            </p>{" "}
                          </span>
                          <span className="km-name">
                            {" "}
                            <p> 4.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:31:01 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="km-name">
                            {" "}
                            <p> 17.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 01:58:35 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-4 mb-4">
                      <h3 className="title-heading">
                        {" "}
                        21 KM (male) 30 & above
                      </h3>
                    </div>

                    <div className="col-md-4">
                      <div className="box-wrapper">
                        <div className="head-item">
                          <span className="number-span-item">
                            <img
                              className="img-fluid number"
                              src={one}
                              width="100%"
                              alt="one"
                            />
                          </span>
                          <span className="name-span-item">
                            {" "}
                            <p>
                              {" "}
                              <b> Jagan Reddy </b>{" "}
                            </p>
                          </span>
                          <span className="age-span-item">
                            {" "}
                            <p> Age </p>{" "}
                            <p>
                              {" "}
                              <b> 46 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                        <hr className="line-wrapper"></hr>
                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={dreessicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Bib{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 21294 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name no-padding">
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={peaceicon}
                                  width="100%"
                                  alt="one"
                                />{" "}
                              </span>{" "}
                              Pace
                            </p>
                            <p>
                              {" "}
                              <b> 04:04 </b>{" "}
                            </p>
                          </span>
                        </div>

                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={gunicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Gun Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 01:26:42 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={chipicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Chip Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 01:25:52</b>{" "}
                            </p>{" "}
                          </span>
                        </div>

                        <div className="last-item">
                          <span className="km-name">
                            {" "}
                            <p> 3.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:14:38 </b>{" "}
                            </p>{" "}
                          </span>
                          <span className="km-name">
                            {" "}
                            <p> 4.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:20:21 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="km-name">
                            {" "}
                            <p> 17.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 01:10:10 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="box-wrapper">
                        <div className="head-item">
                          <span className="number-span-item">
                            <img
                              className="img-fluid number"
                              src={two}
                              width="100%"
                              alt="one"
                            />
                          </span>
                          <span className="name-span-item">
                            {" "}
                            <p>
                              {" "}
                              <b> RAVIVARMA SWAERO </b>{" "}
                            </p>
                          </span>
                          <span className="age-span-item">
                            {" "}
                            <p> Age </p>{" "}
                            <p>
                              {" "}
                              <b> 34 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                        <hr className="line-wrapper"></hr>
                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={dreessicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Bib{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 21278 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name no-padding">
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={peaceicon}
                                  width="100%"
                                  alt="one"
                                />{" "}
                              </span>{" "}
                              Pace
                            </p>
                            <p>
                              {" "}
                              <b> 04:51 </b>{" "}
                            </p>
                          </span>
                        </div>

                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={gunicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Gun Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 01:42:26 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={chipicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Chip Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 01:42:22</b>{" "}
                            </p>{" "}
                          </span>
                        </div>

                        <div className="last-item">
                          <span className="km-name">
                            {" "}
                            <p> 3.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:15:34 </b>{" "}
                            </p>{" "}
                          </span>
                          <span className="km-name">
                            {" "}
                            <p> 4.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:22:24 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="km-name">
                            {" "}
                            <p> 17.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 01:24:13 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="box-wrapper">
                        <div className="head-item">
                          <span className="number-span-item">
                            <img
                              className="img-fluid number"
                              src={three}
                              width="100%"
                              alt="one"
                            />
                          </span>
                          <span className="name-span-item">
                            {" "}
                            <p>
                              {" "}
                              <b> SUNIL AGRAWAL </b>{" "}
                            </p>
                          </span>
                          <span className="age-span-item">
                            {" "}
                            <p> Age </p>{" "}
                            <p>
                              {" "}
                              <b> 49 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                        <hr className="line-wrapper"></hr>
                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={dreessicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Bib{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 21311 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name no-padding">
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={peaceicon}
                                  width="100%"
                                  alt="one"
                                />{" "}
                              </span>{" "}
                              Pace
                            </p>
                            <p>
                              {" "}
                              <b> 04:52 </b>{" "}
                            </p>
                          </span>
                        </div>

                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={gunicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Gun Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 01:43:02 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={chipicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Chip Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 01:42:45 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>

                        <div className="last-item">
                          <span className="km-name">
                            {" "}
                            <p> 3.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:16:48 </b>{" "}
                            </p>{" "}
                          </span>
                          <span className="km-name">
                            {" "}
                            <p> 4.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:23:24 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="km-name">
                            {" "}
                            <p> 17.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 01:25:04 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-4 mb-4">
                      <h3 className="title-heading"> 21 KM (male) Under 30</h3>
                    </div>

                    <div className="col-md-4">
                      <div className="box-wrapper">
                        <div className="head-item">
                          <span className="number-span-item">
                            <img
                              className="img-fluid number"
                              src={one}
                              width="100%"
                              alt="one"
                            />
                          </span>
                          <span className="name-span-item">
                            {" "}
                            <p>
                              {" "}
                              <b> Rahul Turan </b>{" "}
                            </p>
                          </span>
                          <span className="age-span-item">
                            {" "}
                            <p> Age </p>{" "}
                            <p>
                              {" "}
                              <b> 27 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                        <hr className="line-wrapper"></hr>
                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={dreessicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Bib{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 21337 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name no-padding">
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={peaceicon}
                                  width="100%"
                                  alt="one"
                                />{" "}
                              </span>{" "}
                              Pace
                            </p>
                            <p>
                              {" "}
                              <b> 04:01 </b>{" "}
                            </p>
                          </span>
                        </div>

                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={gunicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Gun Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 01:25:01 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={chipicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Chip Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 01:24:55 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>

                        <div className="last-item">
                          <span className="km-name">
                            {" "}
                            <p> 3.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:14:48 </b>{" "}
                            </p>{" "}
                          </span>
                          <span className="km-name">
                            {" "}
                            <p> 4.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:20:30 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="km-name">
                            {" "}
                            <p> 17.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 01:10:39 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="box-wrapper">
                        <div className="head-item">
                          <span className="number-span-item">
                            <img
                              className="img-fluid number"
                              src={two}
                              width="100%"
                              alt="one"
                            />
                          </span>
                          <span className="name-span-item">
                            {" "}
                            <p>
                              {" "}
                              <b> DAYYALA SHIVAKUMAR </b>{" "}
                            </p>
                          </span>
                          <span className="age-span-item">
                            {" "}
                            <p> Age </p>{" "}
                            <p>
                              {" "}
                              <b> 19 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                        <hr className="line-wrapper"></hr>
                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={dreessicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Bib{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 21291 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name no-padding">
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={peaceicon}
                                  width="100%"
                                  alt="one"
                                />{" "}
                              </span>{" "}
                              Pace
                            </p>
                            <p>
                              {" "}
                              <b> 04:26 </b>{" "}
                            </p>
                          </span>
                        </div>

                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={gunicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Gun Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 01:34:11 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={chipicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Chip Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 01:33:34 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>

                        <div className="last-item">
                          <span className="km-name">
                            {" "}
                            <p> 3.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:13:55 </b>{" "}
                            </p>{" "}
                          </span>
                          <span className="km-name">
                            {" "}
                            <p> 4.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:19:37 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="km-name">
                            {" "}
                            <p> 17.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 01:14:46 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="box-wrapper">
                        <div className="head-item">
                          <span className="number-span-item">
                            <img
                              className="img-fluid number"
                              src={three}
                              width="100%"
                              alt="one"
                            />
                          </span>
                          <span className="name-span-item">
                            {" "}
                            <p>
                              {" "}
                              <b> Raviteja Nerellapally </b>{" "}
                            </p>
                          </span>
                          <span className="age-span-item">
                            {" "}
                            <p> Age </p>{" "}
                            <p>
                              {" "}
                              <b> 21 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                        <hr className="line-wrapper"></hr>
                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={dreessicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Bib{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 21171 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name no-padding">
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={peaceicon}
                                  width="100%"
                                  alt="one"
                                />{" "}
                              </span>{" "}
                              Pace
                            </p>
                            <p>
                              {" "}
                              <b> 04:40 </b>{" "}
                            </p>
                          </span>
                        </div>

                        <div className="midddle-item">
                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={gunicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Gun Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 01:49:06 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="bib-name">
                            {" "}
                            <p>
                              {" "}
                              <span>
                                {" "}
                                <img
                                  className="img-fluid icon-small"
                                  src={chipicon}
                                  width="100%"
                                  alt="one"
                                />
                              </span>{" "}
                              Chip Time{" "}
                            </p>{" "}
                            <p>
                              {" "}
                              <b> 01:38:41 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>

                        <div className="last-item">
                          <span className="km-name">
                            {" "}
                            <p> 3.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:15:11 </b>{" "}
                            </p>{" "}
                          </span>
                          <span className="km-name">
                            {" "}
                            <p> 4.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 00:21:31 </b>{" "}
                            </p>{" "}
                          </span>

                          <span className="km-name">
                            {" "}
                            <p> 17.5Km </p>{" "}
                            <p>
                              {" "}
                              <b> 01:21:34 </b>{" "}
                            </p>{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabContent>
              </Tab>
              <Tab eventKey="10km" title="10 Km">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="title-heading">
                      {" "}
                      10 KM (female) 30 & above
                    </h3>
                  </div>
                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={one}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Lalitha W </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 38 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 10618 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 05:29 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:59:44 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:54:45 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:23:52 </b>{" "}
                          </p>{" "}
                        </span>
                        <span className="km-name">
                          {" "}
                          <p> 4.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:34:08 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={two}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Meghna Lowalekar </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 36 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 10145 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 05:43 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:57:46 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:57:11 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:24:16 </b>{" "}
                          </p>{" "}
                        </span>
                        <span className="km-name">
                          {" "}
                          <p> 4.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:34:41 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={three}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Suma S </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 40 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 10523 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 05:50 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 01:00:04 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:58:21 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:26:11 </b>{" "}
                          </p>{" "}
                        </span>
                        <span className="km-name">
                          {" "}
                          <p> 4.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:37:06 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-4 mb-4">
                    <h3 className="title-heading"> 10 KM (female) Under 30 </h3>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={one}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Renu Yadav </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 25 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 10465 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 05:18 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:53:32 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:53:04 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b>00:17:19 </b>{" "}
                          </p>{" "}
                        </span>
                        <span className="km-name">
                          {" "}
                          <p> 4.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:24:20 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={two}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b>Pooja K </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 29 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 10247 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 05:49 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:59:16 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:58:06 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:24:31 </b>{" "}
                          </p>{" "}
                        </span>
                        <span className="km-name">
                          {" "}
                          <p> 4.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:34:46 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={three}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Palvai. Akhila </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 19 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 10586 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 05:51 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:58:49 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:58:34 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:19:16 </b>{" "}
                          </p>{" "}
                        </span>
                        <span className="km-name">
                          {" "}
                          <p> 4.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:27:14 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-4 mb-4">
                    <h3 className="title-heading"> 10 KM (male) 30 & above</h3>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={one}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Razaak Shaik </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 36 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 10652 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 04:26 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:44:33 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:44:23 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:13:52 </b>{" "}
                          </p>{" "}
                        </span>
                        <span className="km-name">
                          {" "}
                          <p> 4.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:19:34 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={two}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Santosh Mallareddi </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 33 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 10146 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 05:04 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:56:43 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:50:36 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b>00:19:55 </b>{" "}
                          </p>{" "}
                        </span>
                        <span className="km-name">
                          {" "}
                          <p> 4.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:28:29 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={three}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Ranbir Singh Bagga </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b>45 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 10600 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 05:04 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:52:21 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:50:42 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:16:58 </b>{" "}
                          </p>{" "}
                        </span>
                        <span className="km-name">
                          {" "}
                          <p> 4.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:24:00 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-4 mb-4">
                    <h3 className="title-heading"> 10 KM (male) Under 30</h3>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={one}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Kiran Deekonda </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 28 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 10193 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 04:21 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:44:25 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:43:32 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:13:37 </b>{" "}
                          </p>{" "}
                        </span>
                        <span className="km-name">
                          {" "}
                          <p> 4.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:18:58 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={two}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Suraj Aiyappa BT </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 20 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 10592 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 04:30 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:45:11 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:44:58 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:16:03 </b>{" "}
                          </p>{" "}
                        </span>
                        <span className="km-name">
                          {" "}
                          <p> 4.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:22:11 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={three}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Manjinder Singh </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 24 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 10590 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 04:30 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:45:11 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:45:01 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:16:06 </b>{" "}
                          </p>{" "}
                        </span>
                        <span className="km-name">
                          {" "}
                          <p> 4.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:22:13 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="5km" title="5 Km">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="title-heading"> 5 KM (female) 30 & above</h3>
                  </div>
                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={one}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Rajeshwari Subramaniam</b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 64 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 6055 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 06:36 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:45:43 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:33:01 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:16:22 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={two}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Meenal Agarwal </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 38 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b>5196</b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 06:47 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:36:32 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:33:56 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:22:10 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={three}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Suma S </b>{" "}
                          </p>
                          <p>Leah Rich</p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 45 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 5646 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 07:00 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:35:57 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:35:02 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:22:12</b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-4 mb-4">
                    <h3 className="title-heading"> 5 KM (female) Under 30 </h3>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={one}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Begari Manasa </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b>18 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 5916 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 05:57 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:29:53 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:29:49 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b>00:18:21 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={two}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b>Nukala. Srivani </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 19 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 5917 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 06:25 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:32:09 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:32:05 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:20:15 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={three}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Boddu. Jhansi </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 18 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 5918 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 06:37 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:33:14 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:33:08 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:20:57 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-4 mb-4">
                    <h3 className="title-heading"> 5 KM (male) 30 & above</h3>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={one}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Dr Raghavendra Babu Y </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 48 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 5571 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 04:27 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:44:33 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:22:19 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:11:01 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={two}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> AMBEDKAR NUKABATTINI </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 40 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 6020 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 04:31 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:22:47 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:22:39 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b>00:14:43 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={three}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Ram Babu </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b>49 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 6083 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 05:07 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:25:45 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:25:37 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b>00:16:30 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-4 mb-4">
                    <h3 className="title-heading"> 5 KM (male) Under 30</h3>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={one}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Robin Singh </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 20 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 5931 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 04:27 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:22:30 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b>00:22:15 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:14:15 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={two}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Shankar Y </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 11 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 5572 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 04:27 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:38:22 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:22:17 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b> 00:11:05 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="head-item">
                        <span className="number-span-item">
                          <img
                            className="img-fluid number"
                            src={three}
                            width="100%"
                            alt="one"
                          />
                        </span>
                        <span className="name-span-item">
                          {" "}
                          <p>
                            {" "}
                            <b> Raul </b>{" "}
                          </p>
                        </span>
                        <span className="age-span-item">
                          {" "}
                          <p> Age </p>{" "}
                          <p>
                            {" "}
                            <b> 22 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                      <hr className="line-wrapper"></hr>
                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={dreessicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Bib{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 5938 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name no-padding">
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={peaceicon}
                                width="100%"
                                alt="one"
                              />{" "}
                            </span>{" "}
                            Pace
                          </p>
                          <p>
                            {" "}
                            <b> 04:30 </b>{" "}
                          </p>
                        </span>
                      </div>

                      <div className="midddle-item">
                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={gunicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Gun Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:22:45 </b>{" "}
                          </p>{" "}
                        </span>

                        <span className="bib-name">
                          {" "}
                          <p>
                            {" "}
                            <span>
                              {" "}
                              <img
                                className="img-fluid icon-small"
                                src={chipicon}
                                width="100%"
                                alt="one"
                              />
                            </span>{" "}
                            Chip Time{" "}
                          </p>{" "}
                          <p>
                            {" "}
                            <b> 00:22:30 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>

                      <div className="last-item">
                        <span className="km-name">
                          {" "}
                          <p> 3.5Km </p>{" "}
                          <p>
                            {" "}
                            <b>00:14:25 </b>{" "}
                          </p>{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
