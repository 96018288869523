import React from "react";

import Videopopup from "../feb2023/videopopup";

export default function Videopostruncontent() {
  return (
    <div className="videopostrin-wraper mt-5 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="title-heading"> VIDEO POST RUN </h3>
              <Videopopup />
          </div>
        </div>
      </div>
    </div>
  );
}
