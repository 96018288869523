import React from "react";
import { Modal } from "react-bootstrap";

import videopost from "../../assets/images/feb2023/videopost/videopost.png";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="video">
          <iframe
            src="https://www.youtube.com/embed/ZujB55bLhnk"
            title="GKR"
            description="GkR Video post"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            width="100%"
            height="410px"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Videopopup() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <span
        onKeyDown={() => setModalShow(true)}
        onClick={() => setModalShow(true)}
        role="button"
        tabIndex="0"
      >
        <img
          className="img-fluid videopart"
          src={videopost}
          width="100%"
          alt="VIDEO POST RUN"
        />
      </span>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Videopopup;
